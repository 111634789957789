import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


export function Pyp() {
    return (
        <>
            <section id="chooseassignment">
                <div className="assignment">
                    <Link to="add">
                        <div className="chooseone"><p className="icon">+</p> ADD PAPER</div>
                    </Link>
                    <Link to="view">
                        <div className="chooseone"><p className="icon">👁‍🗨</p> VIEW PAPERS</div>
                    </Link>

                </div>
            </section>
        </>
    )
}


export const PaperUploader = () => {
    const [program, setProgram] = useState('');
    const [coursecode, setCoursecode] = useState('');
    const [year, setYear] = useState(null);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(0);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        const formData = new FormData();
        formData.append('type', 'upload');
        formData.append('program', program);
        formData.append('coursecode', coursecode);
        formData.append('year', year);
        formData.append('file', file);

        try {
            const response = await axios.post('https://ignoutech.pythonanywhere.com/assets/previouspaper', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(progress);
                },
            });

            console.log('File uploaded successfully:', response.data);
            // Reset form
            setProgram('');
            setCoursecode('');
            setYear('');
            setFile(null);
            setProgress(0);
            setLoading(false)
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <>
        

            <section id="chooseassignment">
                <div className="assignment">
                    <div className="info">
                        <form onSubmit={handleSubmit}>
                            <div className="programinfo">
                                <h2>Add Paper</h2>


                                <div className="row">
                                    <label htmlFor="program">
                                        <input type="text" id="program" placeholder="PROGRAM" onChange={(e) => setProgram(e.target.value)} />
                                    </label>
                                    <label htmlFor="program">
                                        <input type="text" id="program" placeholder="Course Code" onChange={(e) => setCoursecode(e.target.value)} />
                                    </label>

                                </div>
                                    <label htmlFor="coursecode">

                                        <input type='number' name="coursecode" id="code" placeholder='Year' className='input' onChange={(e) => setYear(e.target.value)}/>
                                            
                                    </label>

                            </div>

                            <div className="programinfo">
                                <h2>File </h2>
                               
                                <div className="file">

                                    <label htmlFor="file">
                                        <input type="file" accept='.pdf' id="file" onChange={handleFileChange} required />
                                    </label>


                                </div>


                            </div>
                            <input type="submit" className='btn' value="UPLOAD"  />
                        </form>
                        {
                            loading && <div className="progress">
                                <span style={{ width: progress + '%' }}>
                                    <h2>

                                        {progress}%
                                    </h2>

                                </span>

                            </div>

                        }


                    </div>
                </div>
            </section>

        </>
    );
};
