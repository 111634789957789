import React, { useState, useEffect } from 'react';
import axios from 'axios';


export function Notifications() {
    const [filename, setFilename] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [saveassignment, setUploadProgress] = useState(0);
    const [uploadMessage, setUploadMessage] = useState('');
    const [semester, setSemester] = useState('');
    const [program, setProgram] = useState('');
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const uploadFile = () => {
        setLoading(true);
        setUploadProgress(0);
        if (!selectedFile) {
            
        }

        const formData = new FormData();
        formData.append('type', 'create');
        formData.append('title', program);
        formData.append('para', code);
        formData.append('file', selectedFile);
        formData.append('filename', filename);


        axios.post('https://ignoutech.pythonanywhere.com/assets/notifications', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setUploadProgress(progress);
            }

        })
            .then(response => {
                setUploadMessage(response.data.message);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
            });
    };
    return (
        <>
            <section id="chooseassignment">
                <div className="assignment">
                    <div className="info">
                        <div className="programinfo">
                            <h2>Notification details</h2>

                            <label htmlFor="program">
                                <input type="text" id="program" placeholder="Heading" onChange={(e) => setProgram(e.target.value)} />
                            </label>
                            <label htmlFor="filename">
                                <input type="text" id="filename" placeholder="File Name" onChange={(e) => setFilename(e.target.value)} />
                            </label>
                            <div className="row">
                               

                                <textarea style={{ background: "transparent", border: '1px solid gray', outline: 'none', borderRadius: '10px', color: 'white', padding: '10px',width:'-webkit-fill-available',margin:'4px' }} name="" id="" onChange={(e) => setCode(e.target.value)} placeholder='About'></textarea>

                            </div>

                        </div>
                        

                        <div className="programinfo">
                            <h2>File </h2>
                            <div className="file">
                                <label htmlFor="file">
                                    <input type="file" accept='.pdf' id="file" onChange={handleFileChange} />
                                </label>
                            </div>


                        </div>
                        <input type="button" className='btn' value="UPLOAD" onClick={uploadFile} />
                        {
                            loading && <div className="progress">
                                <span style={{ width: saveassignment + '%' }}>
                                    <h2>

                                        {saveassignment}%
                                    </h2>

                                </span>

                            </div>

                        }


                    </div>
                </div>
            </section>
        </>
    )
}
